.nova-date-search-box{
    display: flex;
align-items: center;
flex-wrap: nowrap;
border-radius: 3px;
background-color: #f5f6f6;
height: 40px;
}

.nova-date-search-input {
    width: 100%;
    position: relative;
}
.rdatez .cal-icon {
    fill: #0575e6!important;
}
