[dir=rtl] .Doctor-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5rem;
    width: calc(100% - 306px);
}
[dir=rtl] .Admin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5rem;
    width: calc(100% - 618px);
}
[dir=rtl] .SiteAdmin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5rem;
    width: calc(100% - 306px);
}
[dir=rtl] .EyeSwiftSuperAdmin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5rem;
    width: calc(100% - 938px);
}
@media only screen and (max-width: 1425px) {
    [dir=rtl] .EyeSwiftSuperAdmin-TabTools{
        padding-right: 0;
        width: 100%;
    }
    [dir=rtl] .TabTools-container{
        float: right;
    }
}
[dir=rtl] .search-wrapper {
    position: relative;
    min-width: 35%;
    width: 65%;
    float: left;
    margin-right: 5px;

}

@media (min-width: 1100px)  {
    [dir=rtl] .search-wrapper {
        position: relative;
        min-width: 30%;
        width: 45%;
        float: left;
    }
}
[dir=rtl] .nova-input{
    height: 40px;
    width: 100%;    object-fit: contain;
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    padding-left: 36px;
    padding-right: 10px;
    outline: none;
}
[dir=rtl] .search-wrapper .input-pic {
    position: absolute;
    top: 10px;
    left: 10px;
}
[dir=rtl] .button-wrapper{
    position: relative;
    min-width: 135px;
    float: left;
    margin-right: 14px;
}

[dir=rtl] .rounded-button-wrapper{
    position: relative;
    width: 40px!important;
    height: 40px!important;
    float: left;
    margin-right: 14px;
}
