.nova-info{
    display: flex;
    position: relative!important;
}
.nova-info-main-container{
position: relative!important;
}
.nova-info-container {
    display: inline-grid;
    height: max-content;
    position: relative!important;

}
.nova-info-patients-status-container{
    margin-bottom: 10px;
    width: 100%;
}
[dir=ltr] .nova-info-devices-status-container{
    margin-bottom: 10px;
    left: 0;
    float: left;
}
[dir=ltr] .nova-info-label{
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: left;
    color: #0575e6;
}
[dir=ltr] .single-nova-info-block{
    height: 120px;
    min-width: 280px;
    background-color: #f9f9f9;
    border-radius: 10px;
    position: relative!important;
    margin-right: 20px;
    padding: 0 20px;
    display: flex;
    flex: 1;
    align-items: center;
}
.nova-info-devices-status-info{
    display: flex;
}
.single-nova-info-block:hover{
    opacity: 0.7;
    cursor: pointer;
}
.single-nova-info-block-value:hover{
    opacity: 0.7;
    cursor: pointer;
}
.single-nova-info-block-label:hover{
    opacity: 0.7;
    cursor: pointer;
}
[dir=ltr] .single-nova-info-block-value{
    font-family: OpenSan, sans-serif;
    font-size: 50px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: left;
    margin-bottom: 0;
    margin-right: 20px;
    color: #0575e6;
}
.nova-info-patients-status-info{
    display: flex;
}
[dir=ltr] .single-nova-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    /* width: 100%; */
    float: left;
    /* margin-top: 35px; */
    margin-bottom: 0;
}


[dir=ltr] .single-nova-time-info-block {
    height: 40px;
    min-width: 170px;
    position: relative!important;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    /*background-color: #0575e6;*/
    color: #0575e6;
    justify-content: center;
}

[dir=ltr] .single-nova-time-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: left;
    margin-bottom: 0;

}

[dir=ltr] .single-nova-show-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: left;
    margin-bottom: 0;
    color: #0575e6;
    margin-top: 8px;
}
