.ReactTable{
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,0);
}
.ReactTable .rt-tr {
    flex: 1 0 auto;
    display: inline-flex;
}
[dir=ltr] .ReactTable .rt-thead .rt-tr {
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    border-bottom: solid 1px #e9e9e9;
}
/*.devices-table .rt-thead .rt-tr{*/
/*    font-size: 15.2px;*/
/*}*/

/*.devices-table .rt-tbody {*/
/*    color: yellow!important;*/
/*    font-size: 15.2px!important;*/
/*}*/

.-sort-asc, .-sort-desc{
    box-shadow: none !important;
    border: none;
}


.ReactTable .rt-thead.-header{
    box-shadow: none;
    width: 100%;
}
.cursor-pointer:focus{
    width: 0;
}
.ReactTable .rt-th, .ReactTable .rt-td{
    hite-space: nowrap;
    text-overflow: ellipsis;
}
[dir=ltr] .ReactTable .rt-thead .rt-th{
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: transparent;
}
[dir=ltr]  .ReactTable .rt-thead .rt-td{
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: transparent;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer{
    cursor: pointer;
}
.ReactTable .rt-thead .rt-resizable-header-content{
    overflow: hidden;
    text-overflow: ellipsis;
}
[dir=ltr] .rt-resizable-header-content{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
}

[dir=ltr] .ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
}

.ReactTable .rt-tbody {
    width: 100%;
    justify-content: flex-start
}
.ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px #e9e9e9;
    max-height: 50px;
}

.ReactTable .rt-tr {
    padding-left: 10px;
    padding-right: 10px;
}

[dir=ltr] .ReactTable .rt-tbody .rt-td {
    border-right: none;
}
.rt-noData{
    top: 250%
}
.-cursor-pointer:focus{
    outline: none;
}

/**************************************************/

.Table__pagination {
    display: flex;
    justify-content: center;
    padding: 20px 10px;
}

.Table__pageButton {
    font-size: 9px;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.Table__pageButton:disabled {
    cursor: not-allowed;
    color: gray;
}

.Table__pageButton--active {
    color: #45b3e3;
    font-weight: bold;
}

/***********************************************/
[dir=ltr] .nova-select-input {
-webkit-appearance: none;
background: url('../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}
[dir=ltr] .-sort-desc:after {
    content:" ";
    white-space: pre;
    float: left;
    background: url('../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}

[dir=ltr] .-sort-asc:after {
    content:" ";
    white-space: pre;
    float: left;
    background: url('../assets/up-arrow.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}
[dir=ltr] .-cursor-pointer > * {
    float: left;
}
.rt-tr-group{
    max-height: 55px!important;
}
.rt-tr.-odd, .rt-tr.-even{
    height: 55px!important;
    border-bottom: 1px solid #e9e9e9;
}
[dir=ltr] .rt-td{
    background-color:#ffffff;
    margin: auto;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #000000;
}
.locked .rt-td {
    color: #b4b4b4!important;
}
.Table__pageButton{
    height: 30px;
    width: 30px;
    font-family: OpenSan, sans-serif;
    border: 1px solid #e4e4e4;
    font-size: 9px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    color: #0575e6;
    border-radius: 50%;
    text-align: center;
    align-items: center;
}
[dir=ltr] .Table__pageButton{
    margin-right: 10px;
}
.Table__pageButton:focus{
    outline: unset!important;
}
.Table__pageButton:hover{
    border: 2px solid #0575e6;
}
.Table__pageButton--active{
    background-color: #0575e6;
    color: white;
    border: #0575e6;
}
.Table__pageButton_arrow{
    border: unset;
}
.Table__pageButton--active:hover{
    border: unset!important;
    cursor: not-allowed;
}
.Table__pageButton_arrow:hover{
    border: unset!important;
}
.table-filter{
    display: none;
}
[dir=ltr] .filter-toggle {
    position: relative;
    left: 0;
    margin-right: 3px;
    text-decoration: none;
}
.ReactTable .rt-tbody{
    overflow: hidden!important;
}
[dir=ltr] .checkbox-th {
    padding-right: 21px!important;
    text-align: center;
}
[dir=ltr] .version-update{
    text-align: left;
}
[dir=ltr] .version-update-label{
    float: left;
}
[dir=ltr] .version-update-img{
    margin-left: 5px;
    float: left;
}