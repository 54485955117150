.nova-table-tabs-container{
    padding-top: 55px;
}
[dir=ltr] .nova-tab-container:nth-last-child(n+2){
    margin-right: 6px;
}
.nova-tab-container{
    position: relative;
    width: 150px;
    height: 40px;
    -webkit-border-radius:10px;
    -moz-border-radius:10px;
    border-radius:5px;
}
.tab-active{
    background:#0575e6;
    color: var(--white);
}
[dir=ltr] .tab-active:after {
    content:"";
    position:absolute;
    bottom:-8px;
    left:71px;
    border-width:8px 8px 0 8px;
    border-style:solid;
    border-color:rgb(5, 117, 230) transparent;
    display:block;
    width:0
}
.tab-inactive{
    border: 1.5px solid #0575e6;
    color: #0575e6;;
}
.tab-inactive:hover{
    box-shadow: 0 0 3pt 1pt rgb(5, 117, 230);
}
.nova-tab{
    width: inherit;
    height: inherit;
}
.error-svg, .warning-svg{
    position: relative!important;

}
[dir=ltr] .error-label, .warning-label{
    font-size: 14px!important;
    font-weight: normal!important;
    font-stretch: normal!important;
    font-style: normal!important;
    margin-left: 5px!important;
    line-height: 1.71!important;
    letter-spacing: 0.35px!important;
    position: relative!important;
    margin-bottom: 0!important;
}
[dir=ltr] .warning-label{
    font-size: 14px!important;
    font-weight: normal!important;
    font-stretch: normal!important;
    font-style: normal!important;
    margin-left: 5px!important;
    line-height: 1.71!important;
    letter-spacing: 0.35px!important;
    position: relative!important;
    margin-bottom: 0!important;
}
[dir=ltr] .error-message-label, .warning-message-label{
    height: 22px;
    justify-content: start;
    float: left;
}
[dir=ltr] .warning-message-label{
    height: 22px;
    justify-content: start;
    float: left;
}
[dir=ltr] .nova-tab-label{
    font-family: OpenSan, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0;
    text-align: left;

    width: max-content;
}
.tab-active{

}
.tab{
    color: #0599FF;
}
.custom-switch{
    padding: 0!important;
}
.custom-control{
    position: unset!important;
    display: block;
}
.EyeSwiftSuperAdmin-TabTools.custom-control{
    padding: 0!important;
}
.inline{
display: inline-block !important;
}

.block{
    display: block;
}


/**

**/


