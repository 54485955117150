[dir=rtl] .nova-modal-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: 0.9px;
    color: #0575e6;
    text-align: center;
    width: 100%;
    display: block;
    height: max-content;
    float: left;
    padding-top: 30px;
}
[dir=rtl] .nova-modal-footer .btn:nth-last-child(n+2){
    margin-left: 11px;
}
[dir=rtl] .nova-modal-content{
    width: 100%;
    display: block;
    height: max-content;
    float: right;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.4px;
    text-align: center;
}
[dir=rtl] .noMarginRight{
    margin-left: 0!important;
}
[dir=rtl] .MessageRadiosLabel-container:nth-last-child(n+2){
    border-left: 1px solid #e1e1e1;
}