.Patient-form-component{
    padding-bottom: 80px;
    border-top: 1px solid #e9e9e9;
}
.Patient-form-sub-component{
    padding-bottom: 80px;
    border-top: 1px solid #e9e9e9;
    margin-top: -45px;
}
[dir=ltr] .Patient-form-component-header{
    height: 60px;
    width: 100%;
    background-color: #f9f9f9;
    position: relative;
    margin-bottom: 30px;
}
.Patient-form-component-sub-header{
    height: 60px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;

}
[dir=ltr] .Patient-form-component-header-label{
    padding-left: 20px;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    float: left;
    margin-left: 5px;
    margin-top: 21px;
    color: #0575e6;
}
[dir=ltr] .Patient-form-component-sub-header-label{
    padding-left: 20px;
    font-family: OpenSan, sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: left;
    margin-top: 15px;
    color: #0575e6;
}
.patient-form-advanced-option{
    position: relative;
    margin-bottom: 80px;
    width: 100%;
    height: 50px;
}
.advanced-options{
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0px!important;
    float: none;
    margin-left: 40px!important;
}
.advanced-options:hover{
    cursor: pointer;
}
.patient-total-compliance-chart-container{
    padding-top: 35px;
}
.total-compliance-Chart-Navigation-title{
    font-family: OpenSan, sans-serif;
    font-size: 22px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    position: relative!important;
}
.total-compliance-Chart-Navigation-value{
    font-family: OpenSan, sans-serif;
    font-size: 35px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.56;
    letter-spacing: 0.8px;
    text-align: center;
    color: #0575e6;
    top: 56.5%!important;
}
