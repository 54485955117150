.nova-patient-form-header{
    background: url('../assets/newpt.png') no-repeat center;
    padding-left: 30px;
    padding-right: 30px;
}
[dir=ltr] .nova-patient-form-navigator-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    position: relative!important;
}
.nova-patient-form-navigator{
    position: absolute!important;
    margin-top: 13px!important;
}
.nova-patient-form-navigator-step-container{
    height: 45px;
    width: 45px;
    border: solid 1px #0575e6;
    border-radius: 50%;
    background-color: transparent;
    float: left;

    font-family: OpenSan, sans-serif;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;

    color: #0575e6;
}
.pageIndicator-number{
    margin-top: 11.5px;
    text-align: center;
}
.nova-patient-form-navigator-step-container.active{
    background-color: #0575e6;
    color: var(--white);
}
.nova-patient-form-navigator-step-container:nth-last-child(n+2){
    margin-left: 69px;
}
.nova-patient-form-navigator-step-container-separator{
    width: 100px;
    margin-left: 15px!important;
    margin-right: 15px!important;
    float: left;
    height: 1px;
    border: 1px solid #e3e3e3;
    margin-top: 20px;
}
.nova-patient-form-navigator-top{
    position: relative!important;
    display: block;
    width: 100%;

}
.firstPageIndicator{

}
.nova-patient-form-navigator-bottom{
    position: relative!important;
    display: block;
    width: 100%;
    margin-top: 60px!important;
}
.nova-patient-form-navigator-step-info{
    display: inline-block;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: left;
    color: #0575e6;
}
.nova-patient-form-navigator-step-info:nth-last-child(n+2){
    margin-right: 30px;
}
