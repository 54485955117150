[dir=rtl] #popover-basic .arrow:after{
    border-right-color: white!important;
}
[dir=rtl] #popover-basic .arrow:before{
    border-right-color: white!important;
}
[dir=rtl].more-func-name{
    width: 100%;
    height: 30px;
    background-color: transparent;
    box-shadow: unset;
    border: none;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
}

[dir=rtl] .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    /*    border-top: 16px solid #3498db; !* Blue *!*/
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-right: 50%;
    margin-top: 20%;
    animation: spin 2s linear infinite;
    border-top: 16px solid #0575e6;
    border-bottom: 16px solid #0575e6;
}