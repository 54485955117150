[dir=rtl] .nova-tab-container:nth-last-child(n+2){
    margin-left: 6px;
}
[dir=rtl] .tab-active:after {
    content:"";
    position:absolute;
    bottom:-8px;
    right:71px;
    border-width:8px 8px 0 8px;
    border-style:solid;
    border-color:rgb(5, 117, 230) transparent;
    display:block;
    width:0
}
[dir=rtl] .error-label{
    font-size: 14px!important;
    font-weight: normal!important;
    font-stretch: normal!important;
    font-style: normal!important;
    margin-right: 5px!important;
    line-height: 1.71!important;
    letter-spacing: 0.35px!important;
    position: relative!important;
    margin-bottom: 0!important;
}
[dir=rtl] .warning-label{
    font-size: 14px!important;
    font-weight: normal!important;
    font-stretch: normal!important;
    font-style: normal!important;
    margin-right: 5px!important;
    line-height: 1.71!important;
    letter-spacing: 0.35px!important;
    position: relative!important;
    margin-bottom: 0!important;
}
[dir=rtl] .error-message-label{
    height: 22px;
    justify-content: start;
    float: right!important;
}
[dir=rtl] .warning-message-label{
    height: 22px;
    justify-content: start;
    float: right!important;
}
[dir=rtl] .warning-message-label{
    height: 22px;
    justify-content: start;
    float: right;
}
[dir=rtl] .nova-tab-label{
    font-family: OpenSan, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0;
    text-align: right;

    width: max-content;
}
