[dir=rtl] .nova-info-devices-status-container{
    margin-bottom: 10px;
    right: 0;
    float: right;
}
[dir=rtl] .nova-info-label{
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .single-nova-info-block{
    height: 120px;
    min-width: 280px;
    background-color: #f9f9f9;
    border-radius: 10px;
    position: relative!important;
    padding: 0 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    flex: 1;
    color: #0575e6;

}
[dir=rtl] .single-nova-info-block-value{
    font-family: OpenSan, sans-serif;
    font-size: 50px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: right;
    margin-bottom: 0;
    margin-left: 20px;
    color: #0575e6;
}

[dir=rtl] .single-nova-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    /* width: 100%; */
    float: left;
    /* margin-top: 35px; */
    margin-bottom: 0;
}

[dir=rtl] .single-nova-time-info-block {
    height: 40px;
    min-width: 170px;
    position: relative!important;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    color: #0575e6;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    float: right;
    /*background-color: #0575e6;*/


}

[dir=rtl] .single-nova-time-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: right;
    margin-right: 10px;
    margin-bottom: 0;
}

[dir=rtl] .single-nova-show-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: right;
    margin-bottom: 0;
    color: #0575e6;
    margin-top: 8px;

}
