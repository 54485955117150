.PatientsNotes{
    resize: none;
    width: 1356px;
    height: 280px;
}
[dir=rtl] .Patient-personal-Form{
    padding-right: 20px;
    min-height: 160px;
}
[dir=rtl] .Patient-personal-form-container:nth-last-child(n+2){
    float: right;
    margin-bottom: 60px;
}
[dir=rtl] .Patient-personal-form-container{
    height: fit-content;
    float: right;
}
[dir=ltr] .Patient-personal-form-element:nth-last-child(n+2){
    margin-left: 40px;
}
[dir=rtl] .Patient-personal-form-element:nth-last-child(n+2){
    margin-left: 40px;
}

.Patient-Form-next-button{
    height: 60px;
    width: 235px;
    font-family: OpenSan, sans-serif!important;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    color: var(--white);
    float: right;
}
.nova-patient-form-footer{
    padding-bottom: 50px!important;
}
