[dir=rtl] .nova-left-navbar, .nova-middle-navbar, .nova-right-navbar{
    width: fit-content;
    display: flex;
    justify-content: space-between;
}
[dir=rtl] .nova-middle-navbar-content, [dir=rtl] .nova-left-navbar-content{
    margin-left: 15px;
    display: inline-block;
}
[dir=rtl] .nova-navbar-visual-label{
    color:#0056b3;
    margin-left: 15px;
}
[dir=rtl] .nova-left-navbar-visual{
    color:#0056b3;
    margin-left: 15px;
}
[dir=rtl] .nav-link{
    padding-right: 0!important;
}

[dir=rtl] .nova-navbar-singedin-user{
    margin-bottom: 0;
    margin-right: 5px;
}
[dir=rtl] .dropdown-toggle::after{
    content:" "!important;
    white-space: pre;
    float: left;
    background: url('../../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-left: 20px;
    padding-top: 2.5px;
    display: inline-block;
    margin-right: 0!important;
    border: none!important;
}
[dir=rtl] .dropdown-menu{
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    left: 0;
    width: max-content;
}
