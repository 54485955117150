.nova-info{
    width: 100%;
    height: 250px;
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('../assets/loginbg.png') no-repeat center center fixed;
    -webkit-background-size: contain, cover;;
    -moz-background-size: contain, cover;;
    -o-background-size: contain, cover;;
    background-size: contain, cover;;
}

.context-area{
    margin-top: 20px;
    min-height: 460px;
}

[dir=ltr] .Patient-Tab-TreatmentProgress{
    width: calc(100% - 95px);
    display: inline-block;
    margin-right: 10px;
    height: 22px!important;
    float: left;
    vertical-align: top;
}
.progress-bar{
    background-color: #0599ff!important;
    border-radius: 5px!important;
    height: 100% !important;
}
.locked-TreatmentProgress .progress-bar{
    height: 100% !important;
    background-color: #f2f2f2!important;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);

}
.locked-TreatmentProgress {
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
}
.progress{
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white)!important;
    border-radius: 5px!important;
}

[dir=ltr] .Patient-Tab-TreatmentProgress-label{
    width: 92px;
    display: inline-block;
    float: left;
    vertical-align: top;

}
.Patient-Tab-TreatmentProgress-container{
    display: flex;
    align-items: center;
}
.nova-info-active-block{
    background-color: #e0e0e0!important;
}
.nova-info-active-btn{
    background-color: #0575e6;
    color: white!important;
}
