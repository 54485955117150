.nova-main-container{
    width: 100%;
    height: fit-content;
    margin-bottom: 30px;
}
.flag-select__option__label, .flag-select__btn:after, .flag-select__btn[aria-expanded="true"]:after{
    display: none;
}
.flag-select__option__icon {
    position: unset;
}
.flag-select{
    padding-bottom: 30px;
}
[dir=rtl] .react-tel-input .selected-flag .arrow{
    right: 20px;
}
[dir=rtl] .react-tel-input .country-list .flag{
    margin-left: 7px;
    margin-right: 0;
}
[dir=rtl] .react-tel-input .form-control {
    direction: ltr!important;
    text-align: left!important;
    padding-right: 48px!important;
    padding-left: 10px!important;
}
.flag-select__option{
    margin: 0;
    padding: 0;
}
.flag-select__options{
    padding: 0 8px 0;
}
.flag-select__option__icon {
    width: 2em!important;
    height: 1.45em!important;
}

/*///////////////////*/
