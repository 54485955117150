[dir=rtl] .Patient-Tab-TreatmentProgress{
    width: calc(100% - 95px);
    display: inline-block;
    margin-left: 10px;
    height: 22px!important;
    float: right;
    vertical-align: top;
}
[dir=rtl] .Patient-Tab-TreatmentProgress-label{
    width: 92px;
    display: inline-block;
    float: right;
    vertical-align: top;

}

