[dir=rtl] .back-button-container{
    color: #0575e6;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: fit-content!important;
    height: 20px;
    padding-right: 30px;
    float: right;
}
[dir=rtl] .back-arrow{
    width: 20px;
    margin-top: 2px;
    position: relative;
    cursor: pointer;
    float: right;
}
[dir=rtl] .back-path{
    transform: rotate(-90deg);
}
[dir=rtl] .back-label{
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    float: right;
}
