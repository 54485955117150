.faqs-element:nth-last-child(2+n){
    border-bottom: 1px solid #0575e6;
}
[dir=ltr] .faqs-element-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: center;
    color: #0575e6;
    margin-bottom: 0;
}
[dir=ltr] .faqs-element-value{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0;
    margin-left: 10px;
}
#popover-faqs .arrow{
    margin: 0!important;
}
