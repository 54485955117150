.return-nav{
    width: 100%!important;
    height: 50px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    position: relative;
}
[dir=ltr] .back-path{
    transform: rotate(90deg);
}
[dir=ltr] .back-button-container{
    color: #0575e6;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: fit-content!important;
    height: 20px;
    padding-left: 30px;
}

.back-button-container:focus{
    border: transparent;
    outline: transparent;
}

[dir=ltr] .back-arrow{
    width: 20px;
    margin-top: 2px;
    position: relative;
    cursor: pointer;
    float: left;
}

[dir=ltr] .back-label{
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
    float: left;
}
