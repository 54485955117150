.nova-patient-header{
    height: 115px;
    width: 100%;
    position: relative;
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('../assets/loginbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
[dir=ltr] .nova-patient-header-name{
    font-family: OpenSan, sans-serif;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    padding-left: 85px;
}
[dir=ltr] .nova-patient-header-options{
    float: right;
    padding-right: 30px;
}
[dir=ltr] .nova-patient{
    padding-top: 75px;
    position: relative;
    margin-bottom: 30px;
    display: flex;
}
[dir=ltr] .nova-patient-left{
    width: 23%;
    margin-right: 1.5%;
    float: none;
    vertical-align: top;
}

[dir=ltr] .loader {
    border: 16px solid #f3f3f3; /* Light grey */
/*    border-top: 16px solid #3498db; !* Blue *!*/
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-left: 50%;
    margin-top: 20%;
    animation: spin 2s linear infinite;
    border-top: 16px solid #0575e6;
    border-bottom: 16px solid #0575e6;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

[dir=ltr] .nova-patient-body{
    position: relative;
}
.nova-patient-screen-Status{
    width: 100%;
    height: 140px;
    margin-bottom: 15px;
    border: 0!important;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: 0 0 10px 0 rgba(0, 98, 204,0.4);
}
[dir=ltr] .status-label{
    font-family: OpenSan, sans-serif;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: left;
}
.status-icon{
    padding-top: 20px;
}
.link{
    cursor: pointer!important;
}
.status-send-message{
    font-family: OpenSan, sans-serif;
    padding-top: 15px;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
}
.nova-patient-screen-info{
    min-width: 260px;
    height: 437px;
    height: max-content;
    border: 0!important;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.3) 0 0px 1px;
    position: sticky;
    top: 0;
}
[dir=ltr] .patient-device-info{
    padding-top: 20px;
    margin-left: 10px;
}
[dir=ltr] .patient-device{
    width: 95%;
    min-height: 145px;
    background-color: #f9f9f9;
    margin: 0!important;
    float: none;
}
.patient-personal-tr{
    border: none;
}
[dir=ltr] .patient-personal-td-left{
    min-width: 120px;
    text-align: left;
    vertical-align: top;
}
[dir=ltr] .patient-personal-td-right{
    text-align: left;
    vertical-align: top;
}
[dir=ltr] .patient-info-options{
    margin-left: 10px!important;
    margin-top: 30px!important;
}
[dir=ltr] .patient-personal-label{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    cursor: pointer;
}
[dir=ltr] .patient-personal-svg{
    cursor: pointer;
    position: absolute;
    right: 0;
    margin-right: 15px
}
.patient-info-options-element:nth-last-child(n+2){
    border-bottom: 1px solid #e1e1e1;
}
[dir=ltr] .patient-info-options-element{
    width: 100%;
    text-align: left;
    padding-bottom: 10px!important;
    padding-top: 10px!important;
}
.patient-personal-information-view{
    padding-top: 20px;
}
.patient-personal-information-view-container{
    height: 90px;
    background-color: #f9f9f9;
    margin: 0!important;
}
.patient-contact-information-view{
    padding-top: 20px;
}
.patient-contact-information-view-container{
    height: 150px;
    background-color: #f9f9f9;
    margin: 0!important;
}

.patient-notes-view{
    padding-top: 20px;
    width: 95%;
    min-height: 145px;
    background-color: #f9f9f9;
    margin: 0!important;
    float: none;
}
.patient-notes-view-container{
    min-height: 80px;
    max-height: 150px;
    background-color: #f9f9f9;
    margin: 0!important;
    padding: 5px;
}
.patient-options{
    margin-top: 20px!important;
    width: 90%;
    padding-bottom: 10px;
    float: none!important;
}
.patient-options-btn{
    width: 200px;
    height: 31px;
    font-family: OpenSan, sans-serif;

}

.patient-options-btn-update{
    color: white;
    background-color: #0575e6;
}
[dir=ltr] .patient-options-btn:nth-last-child(n+2){
    margin-right: 10px
}
[dir=ltr] .patient-options-btn-update{
    position: relative;
    left: 0;
}
[dir=ltr] .patient-options-btn-delete{
    position: relative;
    right: 0;
}
.nova-patient-right{
    width: 75%;
}
[dir=ltr] .nova-patient-right-left{
    width: 49%;
    margin-right: 2%;
    float: none;
    vertical-align: top;
}
.nova-patient-right-left-element{
    height: 300px;
}
.nova-patient-right-left-element:nth-last-child(n+2){
    margin-bottom: 20px;
}
[dir=ltr] .nova-patient-right-left-top-element:nth-last-child(n+2){
    margin-right: 2%;
}
.nova-patient-right-left-top-element{
    width:49%;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 5px;
}

[dir=ltr] .nova-patient-right-left-top-left-top{
    float: left;
    display: block;
    width: 100%;
    margin-top: 40px!important;
    margin-bottom: 20px!important;
    position: relative;
}
[dir=ltr] .nova-patient-right-left-bottom-left-element{
    float: left;
    display: block;
    width: 100%;
    margin-top: 20px!important;
    height: calc(100% - 70px);
    margin-bottom: 20px!important;
    position: relative;
}
[dir=ltr] .patient-nearVa-value:nth-last-child(n+2){
    margin-right: 10%;
}
@media only screen and (max-width: 1413px) {
    [dir=ltr] .patient-nearVa-value:nth-last-child(n+2){
        margin-right: 0;
    }
    .nova-patient-right-left-bottom-left-element{
        margin-top: 8px!important;
    }
    .patient-nearVa-values-element{
        margin-top: 20px!important;
    }
}
.nova-patient-right-left-bottom-element .patient-page-value-label{
    height: 40px;
}
[dir=ltr] sup {
    top: -20px!important;
    margin-left: 5px;
    font-size: 14px!important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29!important;
    letter-spacing: 0.35px;
    text-align: center;
    color: #0575e6;
}
.patient-value{
    font-family: OpenSan, sans-serif;
    font-size: calc(22px + 0.4vw);
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.8px;
    text-align: center;
    color: #0575e6;
}
[dir=ltr] .patient-page-value-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    color: #000000;
    float: left;
    display: block;
    width: 100%;
}
.nova-patient-right-left-bottom{
    background-color: #f9f9f9;
    border-radius: 5px;
}
.nova-patient-right-left-bottom-element{
    width: 33.3333%;
    height: 80%;
    position: relative!important;
}
[dir=ltr] .nova-patient-right-left-bottom-element:nth-last-child(n+2){
    border-right:1px solid #e1e1e1;
}
.nova-patient-right-right{
    width: 49%;
    border: 1px solid #e1e1e1;
    height: 300px;
    float: none;
    vertical-align: top;
}
[dir=ltr] .nova-patient-right-right-element{
    margin-top: 2%;
    height: 265px;
    background-color: #f9f9f9;
    display: inline-block;
    float: left;
}
[dir=ltr] .nova-patient-right-right-element{
    margin-right: 2%;
}
[dir=ltr] .nova-patient-right-right-left{
    width: 35%;
    margin-left: 2%;
}
.nova-patient-right-right-middle{
    width: 35%;
}
.nova-patient-right-right-right{
    width: 22%;
    background-color: unset!important;
}
.nova-patient-right-right-left-element{
    height: 90%;
    margin-top: 35px;
}

.patient-previous-visit-value{
    margin-top: 40px;
    height: 55px;
    text-align: center;
    width: 100%;
}
.patient-previous-visit-report{
    margin-top: 35px!important;
}
.patient-Next-visit-btn{
    margin-top: 35px!important;
}
.patient-visit-date-button{
    font-family: OpenSan, sans-serif;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    font-size: 13px !important;
    line-height: 1;
    letter-spacing: 0.45px;
    width: calc(100% - 60px);
    height: 45px;
    color: white;
    background-color: #0575e6;
}
.patient-visit-date-button:hover{
    color: white!important;
}
.patient-visit-date-button:focus{
    background-color: unset!important;
    color: #0575e6!important;
}
.patient-Next-visit-value{
    height: 55px;
    margin-top: 40px;
    text-align: center;
    width: 100%;
}
[dir=ltr] .patient-personal-td-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.35px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0;
    word-break: break-word;
}

.add-new-visit-btn{
    width: 100%;
    height: calc(90px + 1.5vw);
    position: relative;
    top: 0;
}
#filler{
    height: calc(100% - 3vw - 180px);
}
.visit-history-btn{
    width: 100%;
    height: calc(90px + 1.5vw);
    position: relative;
    bottom: 0;
}
.patient-timeline-btn-label{
    display: block;
    padding-top: 5px;
}
[dir=ltr] .nova-patient-right-element{
    margin-bottom: 20px;
}
[dir=ltr] .patient-profile-visual-acuity-container{
    width: 49%;
    border: 1px solid #e1e1e1;
    height: 460px;

    float: none;
    vertical-align: top;
    display: inline-block;
    margin-right: 2%;
    padding-top: 24px;
}
.patient-profile-stereo-acuity-container{
    width: 49%;
    border: 1px solid #e1e1e1;
    height: 460px;
    float: none;
    vertical-align: top;
    display: inline-block;
    padding-top: 24px;

}
.patient-profile-visual-acuity-container .Chart-Navigation-title{
    width: 35%;
    position: relative!important;
}
.patient-profile-compliance-container{
    width: 100%;
    border: 1px solid #e1e1e1;
    height: 460px;
    float: none;
    vertical-align: top;
    padding-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
}
.full-width{
    width: 100%;
    height: 67.5px;
}
.patient-profile-visit-history-container, .patient-profile-prescription-container{
    width: 100%;
    border: 1px solid #e1e1e1;
    min-height: 300px;
    float: none;
    vertical-align: top;
    padding-top: 24px;
    padding-left: 30px;
    padding-right: 30px;
}
.patient-profile-treatment-history-container{
    width: 100%;
    border: 1px solid #e1e1e1;
    height: max-content;
    min-height: 150px;
    padding-top: 25px;
    padding-bottom: 25px;
    vertical-align: top;
    padding-right: 40px;
    padding-left: 40px;
}
.patient-profile-visual-acuity-info{
    width: 65%;
    height: 55px;
}
[dir=ltr] .patient-profile-visual-acuity-info-label{
    width: 30%;
    height: 100%;
    background-color: #efefef;
    display: inline-block;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    color: #0575e6;
    float: left;
    text-align: center;
}
.patient-profile-visual-acuity-info-label label{
    position: relative;

}
[dir=ltr] .patient-profile-visual-acuity-info-element{
    width: calc(70% / 3);
    height: 100%;
    background-color: #f9f9f9;
    display: inline-block;
    float: left;
}
#patient-profile-visual-acuity-info-left-rect{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    background-color: #0575e6;
    display: inline-block;
}
#patient-profile-visual-acuity-info-right-rect{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    background-color: #034e99;
    display: inline-block;
}
#patient-profile-visual-acuity-info-binocular-rect{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    background-color: #25a6ff;
    display: inline-block;
}
.patient-profile-visual-acuity-info{
    display: inline-block;
}

.patient-profile-visit-history-table{
    width: calc(100% - 200px);
    display: inline-block;
}
.patient-profile-table{
    width: 100%;
}
.patient-profile-table-body-container{
    border: unset;
}
.patient-profile-table-header{
    background-color: #f9f9f9;
    height: 40px;
    border: unset;
}
[dir=ltr] .patient-profile-table-header-label{
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
}

[dir=ltr] .patient-profile-table-body-label:nth-child(1), .patient-profile-table-header-label:nth-child(1){
    padding-left: 20px;
}
.patient-profile-table-subheader{
    height: 45px;
    border: unset;
}
[dir=ltr] .patient-profile-table-subheader-label{
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: left;
    color: #0575e6;
}
.patient-profile-table-body-element{
    border: unset;
    height: 50px;
}
.patient-profile-table-body-element:nth-last-child(n+2){
    border-bottom: 1px solid #e1e1e1;
}
[dir=ltr] .patient-profile-table-body-label{
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000000;
}
[dir=ltr] .table-Navigation-title{
    font-family: OpenSan, sans-serif;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    display: inline-block;
    float: left;
    min-height: 45px;
}
.small-cell{
    max-width: 55px;
}
[dir=ltr] .patient-profile-visit-history-link{
    text-align: right!important;
    float: right;
}
.patient-profile-visit-history-link:hover{
    text-decoration: underline;
    cursor: pointer;
}
[dir=ltr] .visit-history-new-visit-btn{
    display: inline-block;
    height: 120px;
    width: 150px;
    float: right;
    margin-top: 50px;
}
.patient-profile-table-body-title{
    width: 210px;
    color: #0575e6;
}
.large-cell{
    min-width: 60px;
}
[dir=ltr] .patient-profile-treatment-history-subtitle{
    font-family: OpenSan, sans-serif;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    display: inline-block;
    float: left;
    min-height: 26.25px;
}
.patient-profile-more-switch{
    position: relative;
    height: 30px;
    min-width: 160px;
    width: max-content;
    cursor: pointer;
}
.Chart-option{
    padding-left: 30px;
    padding-right: 30px;
}
.patient-profile-stereo-acuity-container .Chart-option{
    min-height: 67.5px;
}
.va-improvement{
    display: block;
    width: 100%;
}
[dir=ltr] .va-improvement-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000000;
    display: inline-block;
    margin-left: 5px;
}
.improvement-square{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    display: inline-block;
}
.left-improvement-square{
    background-color: #25a6ff;
}
.right-improvement-square{
    background-color: #034e99;
}
.binocular-improvement-square{
    background-color: #0575e6;
}
[dir=ltr] .va-left-improvement-value{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000000;
    display: inline-block;
    margin-left: 15px;
}
[dir=ltr] .patient-profile-visual-acuity-left-info{
    padding-left: 20px;
}
[dir=ltr] .patient-profile-visual-acuity-right-info{
    padding-left: 7px;
}


.nova-patient-statistics-boxes
{
    display: flex;
    justify-content: space-between;
}

[dir=ltr] .nova-patient-statistics-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 10px;
}
[dir=ltr] .nova-patient-statistics-left-box{
    min-width: 190px;
    width: 18%;
    height: 130px;
    /*margin: 10px;*/
    padding: 40px 77px;
    object-fit: contain;
    border-radius: 3px;
    /*
    border: solid 1px #f9f9f9;
    */
    background-color: #f9f9f9;
    align-items: center;
}

[dir=ltr].nova-patient-statistics-left-box:nth-last-child(n+2){
    margin-right: 20px;
}

.nova-patient-statistics-right-box{
    min-width: 900px;
    height: 130px;
    padding: 5px 20px 5px 11px;
    object-fit: contain;
    border-radius: 5px;
    /*
    border: solid 1px #e1e1e1;
    */
    background-color: #f9f9f9;
}

.nova-patient-statistics-right-box-title{
    width: 100%;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: center;
    color: #0575e6;
}


.patient-test-block-value{
    width:100%;
    font-family: OpenSan, sans-serif;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: center;
    color: #0575e6;
}

.patient-test-block-label{
    width:100%;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: center;
    color: #000000;
}

.check-box-style{
    width: 19px;
    height: 17px;
    object-fit: contain;
    fill: #0575e6;
    text-align: center;
    display: flex;
}


.label-svg{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width:125px;
}

[dir=ltr] .label-table{
    font-family: OpenSan, sans-serif;
    margin-bottom: 0;
    position: absolute;
}

.label-disabled{
    font-family: OpenSan, sans-serif;
    color: #b4b4b4;
}

.right-box-items{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 0px;
}

.right-box-items-1st{
    text-align: center;
    justify-content: space-between;
    line-height: 1.5;
}

.hide-block{
    display: none;
}

[dir=ltr] .nova-patient-visits-history-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
    margin-top: 40px;
}


.nova-patient-visits-history-right-box{
    min-width: 320px;
    width: 20%;
    height: 267px;
    margin: 10px;
    padding: 15px 50px 15px 50px;
    object-fit: contain;
    border-radius: 5px;
    /*
    border: solid 1px #e1e1e1;
    */
    background-color: #f9f9f9;
    justify-content: space-between;
}

.nova-patient-visits-history-box-title{
    width: 100%;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: center;
    color: #0575e6;
}

.nova-patient-previous-visits-history-box-title{
    width: 100%;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: center;
    color: #0575e6;
    margin-bottom:50px;
}

.nova-patient-visits-history-boxes{
    display: flex;
    margin-top: 10px;
}

.see-test-result-label{
    font-size: 14px;
    font-family: OpenSan, sans-serif;
    text-align: center;
    cursor: pointer;
    width: fit-content;

}
.visits-box{
    margin-top: 60px;
    text-align: center;
}

.remote-box{
    margin-left: 10px;
}

.last-visit-date{
    font-size: 32px;
    font-family: OpenSan, sans-serif;
    text-align: center;
    color: #0575e6;
}

.first-data-label{
    font-size: 14px;
    font-family: OpenSan, sans-serif;
    color: #0575e6;
    margin-left: 19px;
    margin-top: 10px;
}

.second-label{
    font-size: 14px;
    font-family: OpenSan, sans-serif;
    margin-left: 19px;
    margin-top: 10px;
}
.date-input{
    font-size: 14px;
    font-family: OpenSan, sans-serif;
    margin-left: 10px;
    margin-top: 10px;
}

.nova-patient-remote-data{
    width: 238px;
    height: 87px;
    border-radius: 5px;
    border: solid 1px #e1e1e1;
    text-align: left;
    margin-top: 20px;
}
.reschedule-button{
    width: 200px;
    height: 31px;
    font-family: OpenSan, sans-serif;
    color: white;
    margin-left: 19px;
    object-fit: contain;
    border-radius: 5px;
    text-align: center;
    background-color: #0575e6;
}

.nova-patient-visits-history-table-box{
    width: 54%;
    padding: 10px 0px 10px 0px;
}

[dir=ltr] .visit-history-table{
    width: 100%;
    line-height: 3.5;
    font-size: 14px;
    font-family: OpenSan, sans-serif;
}
.table-header-block{
    background-color: #f9f9f9;
    width: 100%;
}

.table-header-label{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    color: #0575e6;
    /*width: 100%;*/
    height: 38px;
    margin-top: 10px;
    font-weight: 300;
}


[dir=ltr] .last-column{
text-align: right;
    padding: 0 15px 0 0;
}

.second-column{
    width: 200px;
}

.info-box-style{
    width: 40px;
    height: 17px;
    object-fit: contain;
    fill: #0575e6;
    text-align: center;
    margin-left: 5px;
}

.search-box-style{
    width: 40px;
    height: 17px;
    object-fit: contain;
    text-align: center;
    margin-left: 5px;
    fill: black;
}

[dir=ltr] .label-info-symbol{
    display: flex;
    align-items: center;
    width:100px;
}

[dir=ltr] .test-results-show-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: left;
    margin-bottom: 0;
    color: #0575e6;
    margin-top: 8px;
}

[dir=ltr] .test-results-info-block{
height: 40px;
    position: relative!important;
    min-width: 100px;
    max-width: 100px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    color: #0575e6;
    justify-content: center;
}

[dir=ltr] .test-results-info-block-select{
    height: 40px;
    min-width: 130px;
    max-width: 130px;
    position: absolute;
    right: 20px;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    background-color: #0575e6;
    color: var(--white);
    justify-content: center;
}


.test-results-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: left;
    margin-bottom: 0;
}

.test-results-tabs{
    margin-top: 15px;
    display: flex;
    position: sticky;
    top: 0px;
    padding: 10px 0px;
    background-color: var(--white);
    z-index: 2;
}

.visual-unit-label{
    margin-left: 30px;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    color: #0575e6;

}
.glasses-style{
    width: 45px;
    height: 30px;
    object-fit: contain;
    display: flex;
    fill: #000000;
}

.title-svg{
    display: flex;
    align-items: center;
    width:50%;
}

[dir=ltr] .patient-act-chart-text{
    font-family: OpenSan, sans-serif;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: 0.65px;
}

[dir=ltr] .patient-act-chart-last{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    float: right;
}

.patient-dashboard-chart-area{
    padding: 10px;
    height: 490px;
    width: 49%;
    border: solid 1px #e1e1e1;
    border-radius: 5px;
    background-color: var(--white);
    position: relative;
}

.patient-chart-topping{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.patient-chart-indication-area{
    background-color: #f9f9f9;
    display: flex;
    width: 50%;
    height: 40px;
}
.patient-fusional-chart-indication-area{
    background-color: #f9f9f9;
    display: flex;
    width: 100%;
    height: 30px;

}

.patient-chart-1st-indication .patient-chart-indication-color{
    background-color:#25a6ff ;
}
.patient-chart-2nd-indication .patient-chart-indication-color{
    background-color: #0575e6;
}

.patient-chart-3rd-indication .patient-chart-indication-color{
    background-color: #034e99;
}
.patient-chart-4th-indication .patient-chart-indication-color{
    background-color: #022e5a;
}

[dir=ltr] .patient-chart-indication{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    width:50%;
    margin-left: 10px;
}

[dir=ltr] .patient-chart-indication-color{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    margin-right: 3px;
}

[dir=ltr] .patient_act_charts{
    width: 50%;
    display: flex;
    justify-content: space-between;
    min-width: 1500px;
    max-height: 1000px;
    padding: 5px 20px 5px 11px;
}


[dir=ltr] .patient-chart-indication-text{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000000;
}

.patient-act-Charts-Block:nth-last-child(n+2){
    margin: 20px;
}

.patient-Color-Test-Block{
    display: flex;
    justify-content: space-around;
}

.patient-dashboard-color-test-area{
    width: 49%;
    padding: 20px;
    height: 350px;
    min-width: 750px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid #f9f9f9;
}

.patient-dashboard-AutoCS-area{
    width: 49%;
    padding: 10px;
    height: 350px;
    min-width: 650px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid #e1e1e1;
}

[dir=ltr] .patient-AutoCS-Block{
    display: flex;
    justify-content: space-between;
    min-width: 300px;
    padding: 5px 20px 5px 11px;
}

.patient-reading-tabs{
    display: flex;
    padding: 5px 20px 5px 11px;
}

.patient-show-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    margin-bottom: 0;
    color: #0575e6;
    margin-top: 8px;
    margin-left: 10px;
}


.select-values-info-block{
    cursor: pointer;
    height: 40px;
    min-width: 150px;
    position: relative!important;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    /*background-color: #0575e6;*/
    color: #0575e6;
    justify-content: center;
}

.patient-reading-charts{
    width: 50%;
    display: flex;
    justify-content: space-between;
    min-width: 1500px;
    margin-top: 5px;
    max-height: 1000px;
}


.test-results-info-block-select-tests{
    height: 40px;
    width: 120px;
    position: relative!important;
    margin-right: 5px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    /*background-color: #0575e6;*/
    background-color: #0575e6;
    color: white!important;
    justify-content: center;
}

#select-test-popover .arrow{
    margin: 0!important;
}
#select-test-popover .arrow{
    display: none;
    border-left-color: white!important;
}
#select-test-popover .arrow:before{
    border-left-color: white!important;
}

.select-test-popover-body, .popover{
    border-radius: 1px;
    background-color: var(--white);
    box-shadow: rgba(0, 0, 0, 0.3) 0 0px 1px;
    border: solid 1px #000000;
}


.popover{
    max-width: 600px!important;
    width: 200px;
}

.select-tests-popup-label{
    width:85%;
    font-family: OpenSan, sans-serif;
    color: black;
    margin-left: 5px;
    margin-bottom: 0px;
    display: inline;
    justify-content: space-between;
}
.select-tests-popup-label:disabled{
    color: #c3c3c3!important;
    font-family: OpenSan, sans-serif;
    width:85%;
    margin-left: 5px;
    margin-bottom: 0px;
    display: inline;
    justify-content: space-between;
}
.select-tests-checkbox-label-not-performed-title{
    width:85%;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
}
.select-tests-checkbox-label{
    justify-content: space-between;
}

.checkbox-popup{
    padding-right: 21px!important;
    justify-content: center;
    width:10px;
}

.checkbox-popup:disabled {
    background: #dddddd;
    padding-right: 21px!important;
    justify-content: center;
    width:10px;
}

[dir=ltr] .patient-info-title-label{
    width: 100%;
    height: 14px;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.45px;
    color: #0575e6;
    margin-left: 14px;
}


[dir=ltr] .patient-personal-td-label-left{
    text-align: left;
    width:60%;
}
[dir=ltr] .patient-contact-td-label-left{
    text-align: left;
    width:50%;
}

.second_table_column{
    color: #0575e6;
    margin-left: 10px;
}

[dir=ltr] .chart-arrow-left{
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: 65px;
    cursor: pointer;
    color: #000000;
}

[dir=ltr] .chart-arrow-right{
    position: absolute;
    z-index: 1;
    bottom: 50px;
    left: 694px;
    cursor: pointer;
    color: #000000;
}

[dir=ltr] .patient-charts-block-label{
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 5px 11px;
}
.patient-Charts-Block{
   margin-top: 5px;
}


.bottom-table-block{
    margin-top: 10px;
}

[dir=ltr].patient-personal-table{
    margin-left: 20px;
}

.search-box-style {
    display: inline-block;
    width: 15px;
    height: 15px;
    stroke-width: 0;
    stroke: black;
    fill: black;
}

.label search-label {
    position: relative;
}

label > .search-box-style {
    position: absolute;
    top: 43px;
    left: 13px;
    transform: translateY(-50%);
    color: black;
}

.search-label > .search-label-input {
    padding-left: calc(15px + 5px); /* icon width + icon padding-left + desired separation*/
    height: 2em;
}

.all-labels{
    line-height: 2.0;
}

.empty-th-table{
    height: 8px;
}