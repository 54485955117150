.dashboard-charts-area{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*padding: 20px 60px;*/
    min-width: 1500px;
    margin-top: 40px;
    max-height: 550px;
}
.dashboard-chart-area{
    width: 47%;
    padding: 20px;
    min-height: 550px;
    min-width: 750px;
    border-radius: 20px;
    background-color: var(--white);
    -webkit-box-shadow: 0px 0px 17px 8px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 17px 8px rgba(0,0,0,0.2);
}
.chart-topping{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}
.chart-container{
    display: block;
    height: calc(100% - 40px);
}
.chart-indication-area{
    background-color: #f9f9f9;
    display: flex;
    min-width: 170px;
    justify-content: space-around;
    height: 40px;
}
.chart-indication{
    display: flex;
    justify-content: left;
    align-items: center;
}
.chart-indication-text{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: left;
    color: #000000;
}
.chart-indication-color{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    margin-right: 3px;
}
.chart-1st-indication .chart-indication-color{
    background-color: #37bc9b;
}
.chart-2st-indication .chart-indication-color{
    background-color: #25a6ff;
}
.chart-title{
    font-family: OpenSan, sans-serif;
    font-size: 28px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: left;
    color: #0575e6;
}

