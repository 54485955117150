.TreatmentHistory-radio-container{
    display: inline-block;
    width: 335px;
}
.TreatmentHistoryLabel{
    width: 335px!important;
}
.TreatmentHistory-radio-container:nth-last-child(n+2){
    margin-right: 5px;
}
.TreatmentHistoryNotes{
    resize: none;
    width: 1356px;
    height: 130px;
}
.AdvanceNotes{
    resize: none;
    width: 100%!important;
    height: 280px!important;
    padding-top: 10px!important;
}
