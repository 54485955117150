html, body, #root{
  height: 100%!important;
}

.nova-app-container{
  height: calc(100% + 30px)!important;
}
body {
  margin: 0;
  font-family: OpenSan, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 93%!important;
}
body {  min-width:1000px; }

@media (max-width: 1100px)  {
  [dir=ltr] .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 100% !important;
  }
}
@media (max-width: 1400px)  {
  [dir=ltr] .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 95% !important;
  }
}
input[type=checkbox] {
  transform: scale(1.5);
}
.pointer{
  cursor: pointer;
}

.djnakj{
  height: 20px;
  width: 15px;
}