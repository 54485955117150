/****** RegisterForm.jsx******/
[dir=rtl] .sidenav-complete{
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(122,122,122,0.8);
}
[dir=rtl] .close-bar {
    right: 30px;
    transition: 0.5s;
    font-family: OpenSan, sans-serif!important;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: right;
    color: #0575e6;
    text-decoration-color: #0575e6;
}
[dir=rtl] .save-info{
    left: 20px;
    margin-bottom: 10px;
    transition: 0.5s;
    width: 210px;
}
[dir=rtl] .multi-input-row .register-form-input:nth-last-child(n+2) {
    margin-left: 28px;
}

[dir=rtl] .register-form-input{
    text-align: right;
}
[dir=rtl] .nova-form-input {
    text-align: right !important;
}

[dir=rtl] .nova-checkbox-input{
    margin-right: 10px;
}
[dir=rtl] .nova-form-label{
    font-family: OpenSan, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .form-search .input-pic {
    position: absolute;
    top: 10px;
    left: 10px;
}
[dir=rtl] .register-form-section-label{
    width: 100%;
    height: 30px;
    margin-top: 50px;
    font-family: OpenSan, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.45px;
    text-align: right;
}

