.login-page{
    height: 100%!important;
    width: 100%!important;
    background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url('../assets/loginbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    z-index: -1;
}
.eye-swift-login-main-board{
    position: absolute;
    width: 50%;
    min-width: 370px;
    max-width: 822px;
    height: 501px;
    background-color: #ffffff;
    border-radius: 20px;
    z-index: 1;
    opacity: 1;
}


[dir=ltr] .eye-swift-login-label{
    padding-top: 52px;
    margin-top: 30px!important;
    font-family: OpenSan, sans-serif;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0575e6;
    position: relative;
    left: 50%;
    transform: translate(-50%, -0%);
}
[dir=ltr] .eye-swift-login-label-reset{
    padding-top: 52px;
    font-family: OpenSan, sans-serif;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0575e6;
    position: relative;
    left: 50%;
    transform: translate(-50%, -0%);
}
[dir=ltr] .eye-swift-password-confirm-label{
    padding-top: 52px;
    margin-top: -9px!important;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    text-align: left;
    position: relative;
    width: 100%;
    color: #0575e6;
    left: 50%;
    margin-bottom: 70px!important;
    transform: translate(-50%, -0%);
}
.eye-swift-newPassword-label{
    display: block;
    width: max-content;
}

.eye-swift-login-form-container-reset{
    margin-top: 30px;
}
.eye-swift-login-form-container{
    margin-top: 65px;
}
[dir=ltr] .eye-swift-login-form{
    float: left;
    width: 80%;
}
[dir=ltr] .eye-swift-login-input-field{
    width: 100%;
    padding-left: 35px!important;
    height: 40px;
    object-fit: contain;
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    padding-right: 10px!important;
}

.termsofuseclick{
    cursor:pointer;
    color: #25a6ff;
}

.eye-swift-login-form-group{
    margin-bottom: 20px;
    height: max-content;
}

.eye-swift-login-form-button{
    position: absolute;
    width: 90%;
    height: 53px!important;
    border-radius: 5px;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    color: var(--white);

}
.eye-swift-login-footer-container:hover{
    color: darkgray;
    cursor: pointer;
}
[dir=ltr] .eye-swift-login-footer-container{
    position: absolute!important;
    bottom: 90px;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0575e6;
}
.eye-swift-device-register{
    bottom: 30px!important;
    font-size: 16px!important;
}
[dir=ltr] .eye-swift-validation-label{
    position: relative!important;
    top: 30px;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: left;
    color: #0575e6;
}
.nova-sight-login-logo-container{
    width: fit-content;
    bottom: 30px;
    position: absolute!important;
}
.eye-swift-login-logo-container{
    width: fit-content;
    top: 30px;
    position: absolute!important;
}
@media only screen and (max-height: 690px) {
    .eye-swift-login-logo-container, .nova-sight-login-logo-container{
        display: none;
    }
}

.termsofuse-tooltip > .tooltip-inner {
    width: 100%;
    min-width: 500px;
    background-color: white;
    font-size: 14px;
    font-family: OpenSan, sans-serif;
    font-weight: 600;
    color: #0575e6;
}

.login-input-wrapper {
    position: relative;
}
.termsofuse{
    margin-bottom: 0;
    margin-left: 12px;
}
[dir=ltr] .login-input-wrapper .input-pic {
    position: absolute;
    top: 9px;
    left: 10px;
}
.loginError{
    width: fit-content;
    position: absolute!important;
    bottom: 50px;
    text-align: center;
}
@media only screen and (max-height: 550px) {
    .login-version-element{
        display: none;
    }
}
[dir=ltr] .login-version-element{
    position: absolute;
    bottom: 0;
    left: 5px;
}
[dir=ltr] .login-version-element-label{
    font-family: OpenSan, sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0;
}
[dir=ltr] .login-version-element-value{
    font-family: OpenSan, sans-serif;
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0;
    margin-left: 10px;
}
