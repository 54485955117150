.modal-dialog{
    width: 620px!important;
}
.nova-modal-body{
    min-width: 620px!important;
    height: max-content!important;
    background-color: #ffffff;
}
[dir=ltr] .nova-modal-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: 0.9px;
    color: #0575e6;
    text-align: center;
    width: 100%;
    display: block;
    height: max-content;
    float: left;
    padding-top: 30px;
}
.nova-modal-footer{
    width: fit-content;
    display: flex;
    padding-top: 40px;
    padding-bottom: 20px;
}
[dir=ltr] .nova-modal-footer .btn:nth-last-child(n+2){
    margin-right: 11px;
}
.nova-modal-footer .btn{
    width: 180px;
    height: 40px;
}
[dir=ltr] .nova-modal-content{
    width: 100%;
    display: block;
    height: max-content;
    float: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;

    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5rem;
    letter-spacing: 0.4px;
    text-align: center;
}
.modal-close{
    background-color: white!important;
    color: #0575e6!important;
}
.modal-close:hover{
    color: white!important;
}
[dir=ltr] .noMarginRight{
    margin-right: 0!important;
}
@media (min-width: 576px){
    .modal-dialog.nova-modal.modal-dialog-centered{
        max-width: 620px!important;
    }
    .modal-dialog {
        width: 620px!important;
    }
}
.nova-modal-content-extra{
    margin-top: 3px;
    font-size: 16px;
    color: #0575e6;
}
.nova-modal-content-extra:nth-last-child(n+2){
    margin-top: 10px;
}
.Message-modal-content{
    width: max-content;
    height: max-content;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
}
[dir=ltr] .MessageRadiosLabel-container:nth-last-child(n+2){
    border-right: 1px solid #e1e1e1;
}
.MessageRadiosLabel-container{
    height: 40px;
    width: 80px;
    display: inline-block;
    color: #0575e6;
    cursor: pointer;
    margin-bottom: 0;
}
.MessageRadiosLabel-container:hover{
    background-color: blue;
    color: #ffffff;
}
.MessageRadiosLabel{
    font-family: OpenSan, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.4rem;
    letter-spacing: 0.4px;
    text-align: center;
}
.SMS-container-active{
    background-color: #0575e6;
    color: #ffffff;
}
.EMAIL-container-active{
    background-color: #0575e6;
    color: #ffffff;
}
.BOTH-container-active{
    background-color: #0575e6;
    color: #ffffff;
}
