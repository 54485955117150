table { border: none; border-collapse: collapse; }
table tr { border-bottom: 1px solid #f2f2f2; }
table tr:first-child {  border-bottom: none; }

#popover-basic .arrow{
    margin: 0!important;
}
[dir=ltr] #popover-basic .arrow:after{
    border-left-color: white!important;
}
[dir=ltr] #popover-basic .arrow:before{
    border-left-color: white!important;
}
.popover-body, .popover{
    border: 0!important;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: 0 0 10px 0 rgba(0, 98, 204,0.4);
}
.popover{
    max-width: 500px!important;
}
[dir=ltr] .more-func-name{
    width: 100%;
    height: 30px;
    background-color: transparent;
    box-shadow: unset;
    border: none;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
}

.more-func-name:focus{
    outline: transparent;
}
.more-func-name:hover{
    color: #0575e6;
}
.table-more{
    cursor: pointer;
}
.glassModel-td{
    background-color: #e4e4e4;
}
