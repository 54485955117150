.Patient-form{

}
.nova-patient-form-header{
    position: relative;
    height: 130px;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
}

.nova-patient-form-body{
    padding-top: 50px;
    margin-bottom: 30px;
}
.nova-PhoneInput-search-box{
    position: absolute;
    width: 280px!important;
}
.nova-form-PhoneInput-search-box{
    position: absolute;
    width: 180px!important;
}
/*.nova-patient-PhoneInput-search{*/
/*    width: max-content;*/
/*}*/
.nova-patient-PhoneInput-search-box{
    position: absolute;
    width: 325px;
    border: solid 1px #e1e1e1!important;
    background-color: var(--white);
    object-fit: contain;
    border-radius: 5px;
    font-family: OpenSan, sans-serif!important;
    font-weight: 300!important;
    font-stretch: normal!important;
    font-style: normal!important;
    line-height: 1!important;
    letter-spacing: 0.45px!important;
}
.nova-patient-PhoneInput-search-box:focus {
    border: 1px solid #0575e6 !important;
    border-radius: 5px;
    box-shadow: unset !important;
    outline: unset;
}
.Patient-Form-cancel-button{
    font-family: OpenSan, sans-serif!important;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: left;
    color: #0575e6;
    text-decoration-color: #0575e6;
}
.Patient-Form-cancel-button:hover{
    text-decoration: underline;
}
.second-form-container{
    margin-top: 40px!important;
    padding-top: 40px;
    border-top: 1px solid #e9e9e9;
}
[dir=ltr] .Patient-personal-form-container{
    height: fit-content;
}
