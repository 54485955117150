[dir=rtl] .ReactTable .rt-thead .rt-tr {
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
    border-bottom: solid 1px #e9e9e9;
}
[dir=rtl] .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td{
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: transparent;
}
[dir=rtl] .rt-resizable-header-content{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
}

[dir=rtl] .ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
}
[dir=rtl] .ReactTable .rt-tbody .rt-td {
    border-right: none;
}
[dir=rtl] .nova-select-input {
    -webkit-appearance: none;
    background: url('../../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}
.Nova-Select{
    border: unset!important;
}
[dir=rtl] .-sort-desc:after {
    content:" ";
    white-space: pre;
    float: left;
    background: url('../../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
}

[dir=rtl] .-sort-asc:after {
    content:" ";
    white-space: pre;
    float: left;
    background: url('../../assets/up-arrow.svg') no-repeat right;
    background-size: 20px 12px;
    padding-left: 20px;
}
[dir=rtl] .-cursor-pointer > * {
    float: right;
}
[dir=rtl] .rt-td{
    background-color:#ffffff;
    margin: auto;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
    color: #000000;
}
[dir=rtl] .Table__pageButton{
    margin-left: 10px;
}
[dir=rtl] .filter-toggle {
    position: relative;
    right: 0;
    margin-left: 3px;
    text-decoration: none;
}
[dir=rtl] .version-update{
    text-align: right;
}
[dir=rtl] .version-update-label{
    float: right;
}
[dir=rtl] .version-update-img{
    margin-right: 5px;
    float: right;
}