[dir=rtl] .faqs-element-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: center;
    color: #0575e6;
    margin-bottom: 0;
}
[dir=rtl] .faqs-element-value{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
    margin-bottom: 0;
    margin-left: 10px;
}
