
#root{
  background-color: #ffffff;
}
.nova-admin-dashboard{
  background-color:#ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.checkbox{
  margin-top: 7px;
}
.checkbox-button{
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

[dir=ltr] .centered{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.v-centered{
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-0%, -50%);
}

[dir=ltr] .right{
  right: 0;
}

[dir=ltr] .h-centered{
  margin: 0;
  position: relative;
  left: 50%;
  transform: translate(-50%, -0%);
}

.red {
  color: #fa1f1f;
  text-decoration-color: #fa1f1f;
}
.yellow {
  color: #FFCC00;
  text-decoration-color: #FFCC00;
}
.yellow-dark{
  color: #f6bb42;
  text-decoration-color: #f6bb42;
}
.green {
  color: #37bc9b;
  text-decoration-color: #37bc9b;
}
.link{
  text-decoration: none;
}
.link:hover{
  text-decoration: underline;
}
.bolded{
  font-weight: bold;
}

.radio-button{
    display: none;
}
.unmargined-label{
  margin-bottom: 0!important;
}
.undefined{
  color: #949494!important
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
