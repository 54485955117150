/****** RegisterForm.jsx******/
.sidenav {
    width: 0;
    z-index: 1;
    position: relative;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;

}
[dir=ltr] .sidenav-complete{
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgba(122,122,122,0.8);
}
.register-form{
    width: 100%;
    min-height: 657px;
}
.register-form-nav-container{
    width: 100%;
    bottom: 0;
    left: 0;
}
.register-form-nav{
    height: 65px;
    bottom: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    transition: 0.5s;
    display: block;
    background-color: white;
}
.register-form-title{
    width: 100%;
    min-height: 50px;
}
[dir=ltr] .close-bar {
    left: 30px;
    transition: 0.5s;
    font-family: OpenSan, sans-serif!important;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: 0.6px;
    text-align: left;
    color: #0575e6;
    text-decoration-color: #0575e6;
}
.close-bar:hover{
    text-decoration: underline;
}
[dir=ltr] .save-info{
    right: 20px;
    margin-bottom: 10px;
    transition: 0.5s;
    width: 210px;
}
/****** EntryForm.jsx******/
.register-form-container{
    margin-bottom: 50px;
    position: absolute;
}
[dir=ltr] .multi-input-row .register-form-input:nth-last-child(n+2) {
    margin-right: 28px;
}
.register-form-row{
    height: 60px;
    width: 100%;
    margin-bottom: 35px;
}
.register-checkbox-row{
    height: 30px!important;
}
.register-form-note-row{
    height: 190px;
    width: 100%;
    margin-bottom: 25px;
}
.register-form-input.half-row{
    width: 47.2%;
}
.nova-form-input:focus{
    border: 1px solid #0575e6!important;
    border-radius: 5px;
    box-shadow: unset!important;
    outline: unset;
}
.nova-form-input {
    border: solid 1px #e1e1e1;
    background-color: var(--white);
    object-fit: contain;
    border-radius: 5px;
    width: 100% !important;
    height: 40px !important;
    font-family: OpenSan, sans-serif !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: 0.45px !important;
    padding-left: 10px;
    padding-right: 10px;
}
[dir=ltr] .nova-form-input {
    text-align: left !important;
}
[dir=ltr] .nova-checkbox-input{
    margin-left: 10px;
}
.react-tel-input{
    width: 100% !important;
}
.react-tel-input .nova-form-input{
    border: solid 1px #e1e1e1!important;
}
.nova-PhoneInput-dropdown{
    width: inherit!important;
    min-width: 250px;
}
[dir=rtl] .selected-flag{
    padding: 0 8px 0 0!important;
}
.nova-patient-PhoneInput-dropdown{
    width: inherit!important;
    min-width: 425px;
}
.nova-form-textarea{
    min-height: 170px!important;
    resize: none;
    margin-bottom: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.patient-form-label{
    display: block;
}
[dir=ltr] .nova-form-label{
    font-family: OpenSan, sans-serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.85;
    letter-spacing: 0.45px;
    text-align: left;
    color: #0575e6;
}
.eighty-percent-row{
    width: 63.5%;
}
.twenty-percent-row{
    width: 31%;
}
.sixty-percent-row{
    width: 56%;
}
.forty-percent-row{
    width: 38.5%;
}

.form-search{
    position: relative;
}
[dir=ltr] .form-search .input-pic {
    position: absolute;
    top: 10px;
    right: 10px;
}

.Select-input > input {
    outline: none;
}
[dir=ltr] .register-form-section-label{
    width: 100%;
    height: 30px;
    margin-top: 50px;
    font-family: OpenSan, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.45px;
    text-align: left;
}

