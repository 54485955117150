[dir=ltr] .nova-left-navbar, .nova-middle-navbar, .nova-right-navbar{
    width: fit-content;
    display: flex;
    justify-content: space-between;
    margin-right: 3%;
}
[dir=ltr] .nova-middle-navbar-content, [dir=ltr] .nova-left-navbar-content{
    margin-right: 15px;
    display: inline-block;
}

[dir=ltr] .nova-navbar-visual-label{
    color:#0056b3;
    margin-right: 15px;
}
[dir=ltr] .nova-left-navbar-visual{
    color:#0056b3;
    margin-right: 15px;
}

.nova-middle-navbar-dropdown-title{
    display: inline-block;
}
[dir=ltr] .nav-link{
    padding-left: 0!important;
}

[dir=ltr] .nova-navbar-singedin-user{
    margin-bottom: 0;
    margin-left: 5px;
}
.navbar{
    padding-top: 15px!important;
}
[dir=ltr] .dropdown-toggle::after{
    content:" "!important;
    white-space: pre;
    float: right;
    background: url('../assets/down-arrow-copy.svg') no-repeat right;
    background-size: 20px 12px;
    padding-right: 20px;
    padding-top: 2.5px;
    display: inline-block;
    margin-left: 0!important;
    border: none!important;
}
[dir=ltr] .dropdown-menu{
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    right: 0;
    width: max-content;
}
.nova-right-navbar-content button{
    border: unset!important;
    padding-top: 3px!important;
}
.nova-right-navbar-content{
    padding-bottom: 0!important;
}
.nova-right-navbar-content span span svg{
    width: 1.3em;
    height: 1.3em;
}
.nova-right-navbar-content button > span {
    margin: 0!important;
}
.language-select-button:after{
    display: none!important;
}
.language-select-button{

}
.nova-right-navbar-content ul li{
    padding: 2px 15px!important;
}

