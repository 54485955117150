[dir=rtl] .eye-swift-login-label{
    padding-top: 52px;
    font-family: OpenSan, sans-serif;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: right;
    color: #0575e6;
    position: relative;
    left: 50%;
    transform: translate(-50%, -0%);
}
[dir=rtl] .eye-swift-login-label-reset{
    padding-top: 52px;
    font-family: OpenSan, sans-serif;
    font-size: 48px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: right;
    color: #0575e6;
    position: relative;
    left: 50%;
    transform: translate(-50%, -0%);
}
[dir=rtl] .eye-swift-password-confirm-label{
    text-align: right;
    padding-top: 52px;
    margin-top: 0!important;
    font-family: OpenSan, sans-serif;
    font-size: 24px;
    position: relative;
    width: 100%;
    color: #0575e6;
    left: 50%;
    margin-bottom: 70px!important;
    transform: translate(-50%, -0%);
}
[dir=rtl] .eye-swift-login-form{
    float: right;
    width: 80%;
}
[dir=rtl] .eye-swift-login-form-button{
    float: right;
}
[dir=rtl] .eye-swift-login-input-field{
    width: 100%;
    padding-right: 35px!important;
    height: 40px;
    object-fit: contain;
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    padding-left: 10px!important;
}
[dir=rtl] .eye-swift-login-footer-container{
    position: absolute!important;
    bottom: 90px;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .eye-swift-validation-label{
    position: relative!important;
    top: 30px;
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 1.2px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .login-input-wrapper .input-pic {
    position: absolute;
    top: 9px;
    right: 10px;
}
[dir=rtl] .login-version-element{
    position: absolute;
    bottom: 0;
    right: 5px;
}
[dir=rtl] .login-version-element-label{
    font-family: OpenSan, sans-serif;
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
    margin-bottom: 0;
}
[dir=rtl] .login-version-element-value{
    font-family: OpenSan, sans-serif;
    font-size: 10px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
    margin-bottom: 0;
    margin-right: 10px;
}
