.nav{
}
[dir=ltr] .Doctor-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% - 306px);
}
[dir=ltr] .Admin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% - 618px);
}
[dir=ltr] .SiteAdmin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% - 306px);
}
[dir=ltr] .EyeSwiftSuperAdmin-TabTools{
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    width: calc(100% - 930px);
}

@media only screen and (max-width: 1425px) {
    [dir=ltr] .EyeSwiftSuperAdmin-TabTools{
        padding-left: 0;
        width: 100%;
    }
    [dir=ltr] .TabTools-container{
        float: left;
    }
    .search-wrapper{
        width: 66%;
        min-width: 46%;
    }
}
.TabTools-container{
    position: relative;
    top: 0;
}
[dir=ltr] .search-wrapper {
    position: relative;
    min-width: 35%;
    width: 65%;
    float: right;
    margin-left: 5px;
}

@media (min-width: 1100px)  {
    [dir=ltr] .search-wrapper {
        position: relative;
        min-width: 30%;
        width: 45%;
        float: right;
    }
}
.nova-input:focus{
    border: 1px solid #0575e6!important;
    outline: unset!important;
}
[dir=ltr] .nova-input{
    height: 40px;
    width: 100%;    object-fit: contain;
    border: none;
    border-radius: 5px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.11);
    background-color: var(--white);
    padding-left: 10px;
    padding-right: 36px;
    outline: none;
}
[dir=ltr] .search-wrapper .input-pic {
    position: absolute;
    top: 10px;
    right: 10px;
}
.input-pic:hover{
    color: blue;
}

[dir=ltr] .button-wrapper{
    position: relative;
    min-width: 135px;
    float: right;
    margin-left: 14px;
}

[dir=ltr] .rounded-button-wrapper{
    position: relative;
    width: 40px!important;
    height: 40px!important;
    float: right;
    margin-left: 14px;
}

.rounded-btn{
    border-radius: 50%!important;
    width: 40px!important;
    height: 40px!important;
}

.btn:focus{
    background-color: #007bff!important;
    border-color: #007bff!important;
    box-shadow: none!important;
}

.btn:hover{
    background-color: #0069d9!important;
    border-color: #0062cc!important;
    box-shadow: none!important;
}


.abs{
    position: absolute;
}
th.device-errors-table-td {
    min-width: 120px;
    border: none;
    height: 32px;
    padding: 0 5px;
}
thead.device-errors-table-head {
    border-bottom: 1px solid #e9e9e9;
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: .45px;
    color: #0575e6;
}
thead.device-errors-table-head tr.device-errors-table-tr {
    border-bottom: 3px solid #e9e9e9!important;
}
tbody.device-errors-table-body {
    background-color: #fff;
    padding: 10px;
    font-family: OpenSan,sans-serif;
    font-size: 16px;
    line-height: 2;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: .45px;
    color: #000;
}
tr.device-errors-table-tr {
    border-bottom: 1px solid #e9e9e9!important;
}
table.device-errors-table {
    width: 100% !important;
}
[dir=rtl] table.device-errors-table, .device-errors, .device-errors h5{
    text-align: right!important;
}
[dir=ltr] table.device-errors-table, .device-errors, .device-errors h5{
    text-align: left!important;
}
[dir=rtl] .device-errors h5{
    text-align: right!important;
}
[dir=ltr] .device-errors h5{
    text-align: left!important;
}
.device-errors{
    margin-top: 40px;
}
.device-teamviewer{
    margin-top: 20px;
    direction: ltr;
    text-align: left;
}
.device-mediapackage{
    direction: ltr;
    margin-top: 10px;
    text-align: left;
}
[dir=ltr] .device-teamviewer-value{
    margin-left: 20px;
}
[dir=rtl] .device-teamviewer-label{
    margin-right: 20px;
}
