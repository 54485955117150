[dir=rtl] .nova-patient-header-name{
    font-family: OpenSan, sans-serif;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: right;
    color: #0575e6;
    padding-right: 85px;
}
[dir=rtl] .nova-patient-header-options{
    float: left;
    padding-left: 30px;
}
[dir=rtl] .nova-patient-left{
    width: 23%;
    margin-left: 1.5%;
    float: none;
    vertical-align: top;
}
[dir=rtl] .nova-patient-body{
    position: relative;
}
[dir=rtl] .nova-patient{
    padding-top: 75px;
    position: relative;
    margin-bottom: 30px;
    display: flex;
}
[dir=rtl] .status-label{
    font-family: OpenSan, sans-serif;
    padding-top: 10px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.6px;
    text-align: right;
}
[dir=rtl] .patient-personal-td-left{
    min-width: 120px;
    text-align: right;
    vertical-align: top;
}

[dir=rtl] .patient-info-options{
    margin-right: 10px!important;
    margin-top: 30px!important;
}

[dir=rtl] .patient-personal-td-right{
    text-align: right;
    vertical-align: top;
}
[dir=rtl] .patient-personal-label{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
    cursor: pointer;
}
[dir=rtl] .patient-personal-svg{
    transform: rotate(180deg);
    cursor: pointer;
    position: absolute;
    left: 0;
    margin-right: 15px
}
/*[dir=rtl] .patient-info-options-element{
    text-align: right;
}*/
[dir=rtl] .patient-options-btn:nth-last-child(n+2){
    margin-left: 10px
}
[dir=rtl] .patient-options-btn-update{
    position: relative;
    right: 0px;
}
[dir=rtl] .patient-options-btn-delete{
    position: relative;
    left: 0px;
}
[dir=rtl] .nova-patient-right-left{
    width: 49%;
    margin-left: 2%;
    float: none;
    vertical-align: top;
}
[dir=rtl] .nova-patient-right-left-top-element:nth-last-child(n+2){
    margin-left: 2%;
}
[dir=rtl] .nova-patient-right-left-top-left-top{
    float: right;
    display: block;
    width: 100%;
    margin-top: 40px!important;
    margin-bottom: 20px!important;
    position: relative;
}
[dir=rtl] .nova-patient-right-left-bottom-left-element{
    float: right;
    display: block;
    width: 100%;
    margin-top: 20px!important;
    height: calc(100% - 70px);
    margin-bottom: 20px!important;
    position: relative;
}
[dir=rtl] .patient-nearVa-value:nth-last-child(n+2){
    margin-left: 10%;
}
@media only screen and (max-width: 1413px) {
    [dir=rtl] .patient-nearVa-value:nth-last-child(n+2){
        margin-left: 0px;
    }
}
[dir=rtl] sup {
    top: -20px!important;
    margin-right: 5px;
    font-size: 14px!important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29!important;
    letter-spacing: 0.35px;
    text-align: center;
    color: #0575e6;
}
[dir=rtl] .patient-page-value-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    color: #000000;
    float: right;
    display: block;
    width: 100%;
}
[dir=rtl] .nova-patient-right-left-bottom-element:nth-last-child(n+2){
    border-right:1px solid #e1e1e1;
}
[dir=rtl] .nova-patient-right-right-element{
    margin-top: 2%;
    height: 265px;
    background-color: #f9f9f9;
    display: inline-block;
    float: right;
}
[dir=rtl] .nova-patient-right-right-element{
    margin-left: 2%;
}
[dir=rtl] .nova-patient-right-right-left{
    width: 35%;
    margin-right: 2%;
}
[dir=rtl] .patient-personal-td-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.35px;
    color: #0575e6;
    margin-bottom: 0;
    text-align: right;
    word-break: break-word;
}
[dir=rtl] .nova-patient-right-element{
    margin-bottom: 20px;
}
[dir=rtl] .patient-profile-visual-acuity-container{
    width: 49%;
    border: 1px solid #e1e1e1;
    height: 460px;
    float: none;
    vertical-align: top;
    display: inline-block;
    margin-left: 2%;
    padding-top: 24px;
}
[dir=rtl] .patient-profile-visual-acuity-info-label{
    width: 30%;
    height: 100%;
    background-color: #efefef;
    display: inline-block;
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.35px;
    color: #0575e6;
    float: right;
    text-align: center;
}
[dir=rtl] .patient-profile-visual-acuity-info-element{
    width: calc(70% / 3);
    height: 100%;
    background-color: #f9f9f9;
    display: inline-block;
    float: right;
}
[dir=rtl] .patient-profile-table-header-label{
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .patient-personal-td-label-left{
    text-align: right;
    width: 60%;
}

[dir=rtl] .patient-contact-td-label-left{

    text-align: right;
    width:50%;
}
[dir=rtl] .patient-profile-table-body-label:nth-child(1), .patient-profile-table-header-label:nth-child(1){
    padding-right: 20px;
}

[dir=rtl].patient-personal-table{
    margin-right: 20px;
}



[dir=rtl] .nova-patient-statistics-left-box{
    min-width: 190px;
    width: 18%;
    height: 130px;
    padding: 40px 77px;
    object-fit: contain;
    border-radius: 3px;
    background-color: #f9f9f9;
    align-items: center;
}

[dir=rtl] .patient-charts-block-label{
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 5px 0px;
}

[dir=rtl] .patient_act_charts{
    width: 50%;
    display: flex;
    justify-content: space-between;
    min-width: 1500px;
    max-height: 1000px;
    padding: 5px 0px 5px 0px;
}

[dir=rtl] .nova-patient-statistics-left-box:nth-last-child(n+2){
    margin-left: 20px;
}

[dir=rtl] .patient-profile-table-subheader-label{
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: right;
    color: #0575e6;
}
[dir=rtl] .patient-profile-table-body-label{
    font-family: OpenSans, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: right;
    color: #000000;
}
[dir=rtl] .table-Navigation-title{
    font-family: OpenSan, sans-serif;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    text-align: right;
    color: #0575e6;
    display: inline-block;
    float: right;
    min-height: 45px;
}
[dir=rtl] .patient-profile-visit-history-link{
    text-align: left!important;
    float: left;
}
[dir=rtl] .visit-history-new-visit-btn{
    display: inline-block;
    height: 120px;
    width: 150px;
    float: left;
    margin-top: 50px;
}
[dir=rtl] .patient-profile-treatment-history-subtitle{
    font-family: OpenSan, sans-serif;
    width: 100%;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.9px;
    text-align: right;
    color: #0575e6;
    display: inline-block;
    float: right;
    min-height: 26.25px;
}
[dir=rtl] .va-improvement-label{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: right;
    color: #000000;
    display: inline-block;
    margin-right: 5px;
}
[dir=rtl] .va-left-improvement-value{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 0.35px;
    text-align: right;
    color: #000000;
    display: inline-block;
    margin-right: 15px;
}
[dir=rtl] .patient-profile-visual-acuity-left-info{
    padding-right: 20px;
}
[dir=rtl] .patient-profile-visual-acuity-right-info{
    padding-right: 7px;
}

[dir=rtl] .nova-patient-statistics-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: right;
    color: #0575e6;
    margin-bottom: 10px;
}

[dir=rtl] .nova-patient-visits-history-title{
    font-family: OpenSan, sans-serif;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.5;
    letter-spacing: 0.9px;
    text-align: right;
    color: #0575e6;
    margin-top: 40px;
}

[dir=rtl] .patient-act-chart-text{
    font-family: OpenSan, sans-serif;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: 0.65px;
    float: right;
}

[dir=rtl] .patient-act-chart-last{
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.45px;
    float: left;
}


[dir=rtl] .patient-AutoCS-Block{
    display: flex;
    justify-content: space-between;
    width: 50%;
    min-width: 1500px;
    padding: 5px 0px 5px 0px;
}

[dir=rtl] .label-info-symbol{
    align-items: center;
    width:100px;
}
[dir=rtl] .test-results-show-info-block-label{
    font-family: OpenSan, sans-serif;
    font-size: 17px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: center;
    position: relative!important;
    float: right;
    margin-bottom: 0;
    color: #0575e6;
    margin-top: 8px;
}

[dir=rtl] .test-results-info-block-select{
    height: 40px;
    min-width: 130px;
    max-width: 130px;
    position: absolute;
    left: 20px;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    background-color: #0575e6;
    color: var(--white);
    justify-content: center;
}
[dir=rtl] .patient-chart-indication{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    width:50%;
    margin-right: 10px;
}
[dir=rtl] .chart-arrow-left{
    position: absolute;
    z-index: 1;
    bottom: 41px;
    left: 65px;
    cursor: pointer;
    color: #000000;
}
[dir=rtl] .patient-info-options-element{
    width: 100%;
    text-align: right;
    padding-bottom: 10px!important;
    padding-top: 10px!important;
}
[dir=rtl] .patient-chart-indication-text{
    font-family: OpenSan, sans-serif;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: 0.35px;
    text-align: right;
    color: #000000;
}

[dir=rtl] .patient-chart-indication-color{
    width: 10px;
    height: 10px;
    object-fit: contain;
    border-radius: 3px;
    margin-left: 3px;
}
[dir=rtl] .chart-arrow-right{
    position: absolute;
z-index: 1;
    bottom: 41px;
    left: 709px;
    cursor: pointer;
    color: #000000;
}

[dir=rtl] .test-results-info-block{
    height: 40px;
    position: relative!important;
    min-width: 100px;
    max-width: 100px;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    margin-left: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: solid 1px #0575e6;
    color: #0575e6;
    justify-content: center;
}

[dir=rtl] .label-table{
    font-family: OpenSan, sans-serif;
    margin-bottom: 0;
}


[dir=rtl] .patient-info-title-label{
    width: 100%;
    height: 14px;
    font-family: OpenSan, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.89;
    letter-spacing: 0.45px;
    color: #0575e6;
    text-align: right;
    margin-right: 14px;
}

[dir=rtl] .visit-history-table{
    width: 95%;
    min-height: 145px;
    margin: 0!important;
    float: none;
}

[dir=ltr] .patient-device-info{
    width: 95%;
    min-height: 145px;
    background-color: #f9f9f9;
    margin: 0!important;
    float: none;
}

[dir=rtl] .patient-device{
    width: 90%;
    min-height: 145px;
    background-color: #f9f9f9;
    margin: 0!important;
    float: none;
}