[dir=rtl] .filter-label{
    font-family: OpenSan, sans-serif;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-top: 5px;
    margin-bottom: 3px;
    letter-spacing: 0.45px;
    text-align: right;
    color: #0575e6;
    margin-right: 10px;
}