[dir=rtl] .centered{
    margin: 0;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(+50%, -50%);
}

[dir=rtl] .right{
    left: 0;
}

[dir=rtl] .h-centered{
    margin: 0;
    position: relative;
    right: 50%;
    transform: translate(50%, -0%);
    float: right;
}
label.ltr{
    text-align: left!important;
}
.ltr .return-nav .back-button-container , .ltr .return-nav .back-button-container .back-arrow{
    float: left!important;
}
.ltr .return-nav .back-button-container .back-arrow{
    transform: rotate(90deg);
}
